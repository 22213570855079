import { ContentItem } from "../ContentItem/ContentItem"
import { FlyerProd } from "../Flyer/FlyerProd"
import { FaMedal } from "react-icons/fa"
import img1 from "../../images/pexels-marnock-13866715.jpg"
import img2 from "../../images/pexels-mali-maeder-110822.jpg"
import img3 from "../../images/pexels-ion-ceban-ionelceban-2581022.jpg"
import img4 from "../../images/pexels-lstan-5644323.jpg"
import img5 from "../../images/pexels-tim-mossholder-2339180.jpg"


export const ContentList = ({productos, imagenes}) => {

    const productosConImagenes = productos.map((producto) => {
        const imagen = imagenes.find((imagen) => imagen.nam === producto.dato + '.png');
        return { ...producto, ...imagen };
    });

    const protos = productosConImagenes.filter((el) => el.bodega === "Protos" && el.activo).sort((a, b) => b.precio - a.precio);
    const riscal = productosConImagenes.filter((el) => el.bodega === "Herederos de Marqués de Riscal" && el.activo).sort((a, b) => b.precio - a.precio);
    const viana = productosConImagenes.filter((el) => el.bodega === "Príncipe de Viana" && el.activo).sort((a, b) => b.precio - a.precio);
    const codax = productosConImagenes.filter((el) => el.bodega === "Martín Códax" && el.activo).sort((a, b) => b.precio - a.precio);
    const clunia = productosConImagenes.filter((el) => el.bodega === "Clunia" && el.activo).sort((a, b) => b.precio - a.precio);

    const flyerProtos = protos.filter ((el) => el.nombre ===  "Protos'27")
    const flyerRiscal = riscal.filter ((el) => el.nombre ===  "Viña Collada")
    const flyerViana = viana.filter ((el) => el.nombre ===  "Edición Limitada")
    const flyerCodax = codax.filter ((el) => el.nombre ===  "Albariño")
    const flyerClunia = clunia.filter ((el) => el.nombre ===  "Malbec")

    return (
        <>
            <div className="card-content-init">
                <div className="content-logoBox">
                    <img className="content-logoBoxImg" src="./img/bodegas/logo-protos.png" alt="bodega4" style={{width: "240px", height: "200px", margin: "3rem 0 1.5rem"}}/>
                    {/* <div className="content-mencionBox">
                        <p className="content-mencionBoxTitle content-mencionBoxTitle--modify">Fundada en 1985</p>
                    </div> */}
                </div>
                <div className="content-flyer">
                    <img className="content-flyerImage" alt="dest" src={img2}/>
                    {flyerProtos.map((el) => <FlyerProd key={el.id} {...el}/>)}
                </div>
                <div className="card-content-item">
                    {protos.map((el) => <ContentItem key={el.id} {...el}/>)}
                </div>
                <div className="content-logoBox">
                    <div className="content-boxRow">
                        <img className="content-logoBoxImg" src="./img/bodegas/new/marques-riscal-new.png" alt="bodega1"/>
                        <div className="content-mencion">
                            <div className="content-mencionBoxDesc">
                                <div className="content-mencionDes">
                                    <p>Declarado el <strong>2° mejor Viñedo y Bodega del Mundo</strong>, según World's Best VINEYARDS 2022</p>
                                </div>
                            </div>
                            <div className="content-mencionBoxIcon">
                                <FaMedal className="content-mencionIcon"/>
                            </div>
                        </div>
                    </div>
                    <div className="content-mencionBox">
                        <p className="content-mencionBoxTitle">Desde 1858</p>
                        <p className="content-mencionBoxTitle">Más de 160 años de historia y crecimiento</p>
                        <p className="content-mencionBoxTitle">Vivimos por y para el vino.</p>
                    </div>
                </div>
                <div className="content-flyer">
                    <img className="content-flyerImage" alt="dest" src={img1}/>
                    {flyerRiscal.map((el) => <FlyerProd key={el.id} {...el}/>)}
                </div>
                <div className="card-content-item">
                    {riscal.map((el) => <ContentItem key={el.id} {...el}/>)}
                </div>
                <div className="content-logoBox">
                    <img className="content-logoBoxImg content-ajuste" src="./img/bodegas/logo-martin-codax.png" alt="bodega2"/>
                    <div className="content-mencionBox">
                        <p className="content-mencionBoxTitle content-mencionBoxTitle--modify">Fundada en 1985</p>
                    </div>
                </div>
                <div className="content-flyer">
                    <img className="content-flyerImage" alt="dest" src={img4}/>
                    {flyerCodax.map((el) => <FlyerProd key={el.id} {...el}/>)}
                </div>
                <div className="card-content-item">
                    {codax.map((el) => <ContentItem key={el.id} {...el}/>)}
                </div>
                <div className="content-logoBox">
                    <img className="content-logoBoxImg content-ajuste" src="./img/bodegas/new/principe-de-viana.jpg" alt="bodega3"/>
                </div>
                <div className="content-flyer">
                    <img className="content-flyerImage" alt="dest" src={img3}/>
                    {flyerViana.map((el) => <FlyerProd key={el.id} {...el}/>)}
                </div>
                <div className="card-content-item">
                    {viana.map((el) => <ContentItem key={el.id} {...el}/>)}
                </div>
                <div className="content-logoBox">
                    <img className="content-logoBoxImg content-ajuste" src="./img/bodegas/logo-clunia.jpg" alt="bodega5"/>
                </div>
                <div className="content-flyer">
                    <img className="content-flyerImage" alt="dest" src={img5}/>
                    {flyerClunia.map((el) => <FlyerProd key={el.id} {...el}/>)}
                </div>
                <div className="card-content-item">
                    {clunia.map((el) => <ContentItem key={el.id} {...el}/>)}
                </div>
            </div>
        </>
    )
}
