import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../CartContext/CartContext";
import { ItemCount } from "../ItemCount/ItemCount";
import { Modal } from "../Modal/Modal";
import { BsFillQuestionCircleFill } from "react-icons/bs";

export const ItemDetail = ({
  cosecha,
  id,
  bodega,
  nombre,
  precio,
  foto,
  stock,
  precioDolar,
  descUno,
  porcDesc,
}) => {
  //SIEMPRE DESESTRUCTURAR CUANDO SE RECIBE UN OBJETO O ARRAY

  const [cantidad, setCantidad] = useState(0);
  const {
    abrirModal,
    handleVerImg,
    handleProdAdd,
    agregarAlCarrito,
    isInCart,
  } = useContext(CartContext);

  const handlerAgregar = () => {
    if (cantidad === 0) return; //early return

    if (!isInCart(id)) {
      const precioActualizado =
        porcDesc > 0
          ? (precio * precioDolar - precio * precioDolar * (porcDesc / 100))
          : (precio * precioDolar);

      const addItem = {
        id,
        nombre,
        precioActualizado,
        stock,
        cantidad,
        foto,
      };

      agregarAlCarrito(addItem);

      handleProdAdd();
    }
  };

  return (
    <>
      <div className="content-producto">
        <div className="content-img">
          {porcDesc > 0 && (
            <div className="card-off">
              <p>{porcDesc} %</p>
              <p> OFF</p>
            </div>
          )}
          <img
            onClick={handleVerImg}
            style={{ cursor: "pointer" }}
            src={`/${foto}`}
            alt={nombre}
          />
        </div>
        <div className="content-detail">
          <div className="content-text">
            <p className="content-title">{nombre}</p>
            {cosecha ? (
              <p className="content-desc">
                <strong>Cosecha: </strong>
                {cosecha}
              </p>
            ) : null}
            {bodega ? (
              <p className="content-desc">
                <strong>Bodega: </strong>
                {bodega}
              </p>
            ) : null}
            {id === "0105VjaVJHmC1SV9BXLi" ? (
              <p className="content-desc desc-width">{descUno}</p>
            ) : null}
            {id === "01160yB5ulx8pSNyKXcp" ? (
              <p className="content-desc desc-width">{descUno}</p>
            ) : null}
            {precio !== 0 ? (
              <>
                {porcDesc > 0 ? (
                  <>
                    <p className="content-price tachado">
                      U$S {Math.round(precio)} BNA / AR${" "}
                      {Math.round(precio * precioDolar).toLocaleString("es-AR")}{" "}
                      c/u
                    </p>
                    <p className="content-price">
                      U$S {Math.round(precio - precio * (porcDesc / 100))} BNA /
                      AR${" "}
                      {Math.round(
                        precio * precioDolar -
                          precio * precioDolar * (porcDesc / 100)
                      ).toLocaleString("es-AR")}{" "}
                      c/u
                    </p>
                  </>
                ) : (
                  <p className="content-price">
                    U$S {Math.round(precio)} BNA / AR${" "}
                    {Math.round(precio * precioDolar).toLocaleString("es-AR")}{" "}
                    c/u
                  </p>
                )}
              </>
            ) : (
              <p className="content-price">Disponible Proximamente</p>
            )}
            {stock ? (
              <>
                <p className="content-desc stock">EN STOCK</p>
                <Link className="link-detail content-desc" to="/detalle-envio">
                  ENVÍOS 24/72hs
                  <BsFillQuestionCircleFill className="icon-question" />
                </Link>
              </>
            ) : (
              <p className="content-desc stock">SIN STOCK</p>
            )}
          </div>
          <div className="content-btn">
            {isInCart(id) ? (
              <>
                <Link to="/cart" className="card-btn card-success card-size">
                  VER CARRITO
                </Link>
                <Link to="/productos" className="card-size">
                  <button className="card-btn card-size">
                    CONTINUAR COMPRA
                  </button>
                </Link>
              </>
            ) : (
              <>
                {stock ? (
                  <>
                    <ItemCount
                      max={stock}
                      counter={cantidad}
                      setCounter={setCantidad}
                    />
                    <button
                      className="card-btn card-size"
                      onClick={handlerAgregar}
                    >
                      AGREGAR AL CARRITO
                    </button>
                    <Link to="/">
                      <button className="card-btn">VOLVER</button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/">
                      <button className="card-btn">VOLVER</button>
                    </Link>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {abrirModal ? <Modal nombre={nombre} foto={foto} /> : null}
      </div>
    </>
  );
};
