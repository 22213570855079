import { Link } from "react-router-dom";

export const ContentItem = ({
  id,
  nombre,
  precio,
  foto,
  bodega,
  tipo,
  cosecha,
  precioDolar,
  porcDesc,
}) => {
  return (
    <div className="card">
      {porcDesc > 0 && (
        <div className="card-off">
          <p>{porcDesc} %</p>
          <p> OFF</p>
        </div>
      )}
      <div className="card-imageContent">
        <img className="card-img" src={`/${foto}`} alt={nombre} />
      </div>
      <div className="card-text">
        <strong className="card-title">{nombre}</strong>
        <em className="card-desc">
          <strong>Bodega: </strong>
          {bodega}
        </em>
        <em className="card-desc">
          <strong>Tipo de Uva: </strong>
          {tipo}
        </em>
        <em className="card-desc">
          <strong>Cosecha: </strong>
          {cosecha}
        </em>
        {precio !== 0 ? (
          <>
            {porcDesc > 0 ? (
              <>
                <p className="card-price tachado">
                  U$S {Math.round(precio).toLocaleString("es-AR")} BNA / AR${" "}
                  {Math.round(precio * precioDolar).toLocaleString("es-AR")} c/u
                </p>
                <p className="card-price">
                  U$S {Math.round(precio - (precio * (porcDesc / 100))).toLocaleString("es-AR")} BNA / AR${" "}
                  {Math.round((precio * precioDolar) - ((precio * precioDolar) * (porcDesc / 100))).toLocaleString("es-AR")} c/u
                </p>
              </>
            ) : (
              <p className="card-price">
                U$S {Math.round(precio).toLocaleString("es-AR")} BNA / AR${" "}
                {Math.round(precio * precioDolar).toLocaleString("es-AR")} c/u
              </p>
            )}
            <Link to={`/detalle/${id}`}>
              <button className="card-btn">VER MAS</button>
            </Link>
          </>
        ) : (
          <>
            <p className="card-price">Disponible Proximamente</p>
            <Link to={`/detalle/${id}`}>
              <button className="card-btn">VER MAS</button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
